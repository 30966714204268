import React, { useState } from 'react';
import { sk } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { FilterEvent, Select } from '@components';
import { commonCn } from '@utils';

const NavButtonsStyles =
  'ps-inline-flex ps-cursor-pointer ps-select-none ps-items-center ps-justify-center ps-border-0 ps-bg-none ps-no-underline ps-transition-shadow ps-h-[30px] ps-w-[30px] ps-rounded-full ps-text-black data-[state=open]:ps-bg-gray-100 ps-bg-transparent ps-p-0 ps-opacity-50 ' +
  'hover:ps-bg-gray-300/25 hover:ps-ring-0 hover:ps-ring-gray-900/25 hover:ps-opacity-100 hover:ps-ring-offset-0 ' +
  'focus:ps-outline-none focus:ps-ring-2 focus:ps-ring-gray-900/50 focus:ps-ring-offset-0 ' +
  'active:ps-ring-0 active:ps-shadow-defaultActive active:ps-bg-gray-300/40 ' +
  'disabled:ps-pointer-events-none disabled:ps-text-gray disabled:ps-opacity-50';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const getSelectTestId = (ariaLabel: string | undefined) => {
  if (ariaLabel?.includes('Month')) return 'month';
  if (ariaLabel?.includes('Year')) return 'year';
  return undefined;
};

export const YearPicker = ({
  onYearChange,
  value,
  maxYear = 2030,
  minYear = 1920,
  className,
}: {
  onYearChange: (e: FilterEvent, year: number) => void;
  value: number;
  maxYear?: number;
  minYear?: number;
  className?: string;
}) => {
  const handleYearChange = (e: FilterEvent, year: string | undefined) => {
    onYearChange(e, year ? parseInt(year) : value);
  };

  const options = Array.from({ length: maxYear - minYear + 1 }, (_, i) => maxYear - i).map((year) => ({
    code: year,
    name: year,
  }));

  return (
    <Select
      options={options}
      value={value?.toString()}
      size={'sm'}
      className={className}
      onChange={(e) => handleYearChange(e!, e!.target.value)}
    />
  );
};

export const Calendar = ({
  classNames,
  showOutsideDays = true,
  components,
  locale,
  startMonth,
  endMonth,
  ...props
}: CalendarProps) => {
  return (
    <DayPicker
      locale={locale ?? sk}
      captionLayout="dropdown"
      showOutsideDays={showOutsideDays}
      startMonth={startMonth ?? new Date(1900, 0)}
      endMonth={endMonth ?? new Date(2100, 0)}
      classNames={{
        months: 'ps-relative ps-inline-block',
        month: 'ps-space-y-1',
        month_caption: 'ps-flex ps-content-center ps-py-0.5',
        caption_label: 'ps-hidden',
        dropdowns: 'ps-flex ps-gap-1 ps-pl-1',
        nav: 'ps-absolute ps-top-0 ps-right-0 ps-flex ps-items-center ps-space-x-1',
        button_previous: NavButtonsStyles,
        button_next: NavButtonsStyles,
        month_grid: 'ps-w-[250px] ps-border-collapse ps-space-y-0.5',
        weekdays: 'ps-flex ',
        weekday: 'ps-text-muted-foreground ps-rounded-md ps-w-[35px] ps-font-normal ps-text-[0.8rem]',
        week: 'ps-flex ps-w-full ps-mt-1 ',
        day: 'ps-text-center ps-text-sm ps-p-[1px] ps-relative ps-w-[35px] ps-aspect-square focus-within:ps-z-20',
        day_button: 'calendar__day',
        selected: 'calendar__day-selected',
        disabled: 'calendar__day-disabled',
        outside: 'calendar__day-outside',
        hidden: 'calendar__day-hidden',
        today: 'calendar__day-today',
        ...classNames,
      }}
      components={{
        Chevron: ({ ...props }) => {
          if (props.orientation === 'right') return <ChevronRight className="ps-h-3 ps-w-3 sm:ps-h-2 sm:ps-w-2" />;
          return <ChevronLeft className="ps-h-3 ps-w-3 sm:ps-h-2 sm:ps-w-2" />;
        },
        Dropdown: (dropdownProps) => {
          const selectedItem = dropdownProps.options?.find((el) => el.value === dropdownProps.value)?.label;

          const style = dropdownProps.style ?? {};

          // Workaround to set width of the select based on the longest option
          if (selectedItem) {
            const tmp = document.createElement('select');
            tmp.appendChild(document.createElement('option')).textContent = selectedItem;
            tmp.className = 'ps-opacity-0 ps-font-sans ps-text-base';
            document.body.appendChild(tmp);
            style.width = `${tmp.clientWidth + 5}px`;
            tmp.remove();
          }

          const dropdownType = getSelectTestId(dropdownProps['aria-label']);

          return (
            <select
              disabled={dropdownProps.disabled}
              aria-label={dropdownProps['aria-label']}
              value={dropdownProps.value}
              onChange={(e) => {
                // Event propagation is stopped, because it triggers onchange event in <Form> element
                e.stopPropagation();
                if (dropdownProps.onChange) dropdownProps.onChange(e);
              }}
              style={style}
              data-testid={`calendar-${dropdownType}-dropdown-trigger`}
              className={commonCn(
                dropdownProps.className,
                `ps-cursor-pointer ps-rounded ps-border ps-border-transparent ps-bg-transparent ps-p-0 ps-font-sans ps-text-base ps-text-black ps-outline-none ps-ring-0 hover:ps-border-blue focus:ps-border-blue focus:ps-ring-2 focus:ps-ring-blue/25`
              )}
            >
              {dropdownProps.options?.map((opt, index) => <option key={index} {...opt} />)}
            </select>
          );
        },
        ...components,
      }}
      {...props}
    />
  );
};
Calendar.displayName = 'Calendar';
