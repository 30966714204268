import React from 'react';
import { Trash2 } from 'react-feather';
import { Divider, DropdownMenu, DropdownMenuContent, MuiStack, Tooltip, Typography } from '@components';
import { OutlinedFilledButton } from '@components/filter/filter';
import { FilterSelectItem } from '@components/filter/filters/select-filter/dropdown-select-item';
import { TextFilterInput } from '@components/filter/filters/text-filter/text-filter-input';
import { TextFilterComponentProps } from '@components/filter/filters/text-filter/types';

export const TextFilterDefaultLabels = {
  conditionDefault: 'vyberte možnosť',
  conditionsHeader: 'Vyberte možnosť',
  tooltipDelete: 'Vymazať filter',
};

export const TextFilterComponent: React.FC<TextFilterComponentProps> = ({
  label,
  id,
  type,
  value,
  onChange,
  conditions,
  onDeleteFilter,
  deletable = true,
  labels = TextFilterDefaultLabels,
}) => {
  const haveConditions = conditions && conditions.length > 0;
  const selectedCondition = value?.condition ? conditions?.find((c) => c.type === value.condition) : undefined;

  const [conditionSelectOpened, setConditionSelectOpened] = React.useState<boolean>(false);
  const [inputOpened, setInputOpened] = React.useState<boolean>(false);

  return (
    <MuiStack direction={'row'}>
      {haveConditions && <OutlinedFilledButton corners={'rounded-l'}>{label}</OutlinedFilledButton>}

      {haveConditions && (
        <DropdownMenu
          id={`${id}-conditions`}
          open={conditionSelectOpened}
          onOpenChange={setConditionSelectOpened}
          content={
            <DropdownMenuContent className={'ps-p-2'}>
              <Typography variant={'h4'} className={'ps-mx-1 ps-text-gray-800 ps-font-semibold'}>
                {labels?.conditionsHeader}
              </Typography>
              <Divider className={'ps-mx-1'} />
              {conditions?.map((condition, index) => {
                const checked = value?.condition === condition.type;

                return (
                  <FilterSelectItem
                    key={index}
                    index={index}
                    checked={checked}
                    type={'radio'}
                    onChange={(e) => {
                      if (!value) {
                        onChange(e, { id, type, value: null, condition: condition.type });
                      } else {
                        onChange(e, { ...value, condition: condition.type, value: value.value });
                      }
                      setConditionSelectOpened(false);
                      setInputOpened(true);
                    }}
                  >
                    {condition.label}
                  </FilterSelectItem>
                );
              })}
            </DropdownMenuContent>
          }
        >
          <OutlinedFilledButton corners={'square'}>
            {selectedCondition?.selectedLabel ?? selectedCondition?.label ?? labels?.conditionDefault}
          </OutlinedFilledButton>
        </DropdownMenu>
      )}

      {value && (
        <TextFilterInput
          value={value}
          onChange={onChange}
          id={id}
          corners={deletable ? 'square' : 'rounded-r'}
          open={inputOpened}
          onOpenChange={setInputOpened}
        />
      )}

      {deletable && (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <OutlinedFilledButton corners={'rounded-r'} onClick={onDeleteFilter} data-testId={`${id}-delete-filter`}>
              <Trash2 size={20} />
            </OutlinedFilledButton>
          </Tooltip.Trigger>
          <Tooltip.Content align={'start'}>{labels?.tooltipDelete}</Tooltip.Content>
        </Tooltip>
      )}
    </MuiStack>
  );
};
