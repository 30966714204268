import React from 'react';
import { format, getYear } from 'date-fns';
import { Trash2 } from 'react-feather';
import { FilterEvent, OutlinedFilledButton } from '@components';
import { YearPicker } from '@components/calendar';
import { CalendarDropdown } from '@components/filter/filters/date-filter/calendar-dropdown';
import { SelectModeComponent } from '@components/filter/filters/date-filter/select-mode-component';
import { DateFilterComponentProps, SelectDateConditionType } from '@components/filter/filters/date-filter/types';
import IconButton from '@components/icon-button';

export const DateFilterDefaultLabels = {
  conditionsHeader: 'Vyberte podmienku',
};

export const PredefinedRangesConfig = {
  thisWeek: {
    getValue: () => {
      const today = new Date();
      const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      return { from: firstDayOfWeek, to: lastDayOfWeek };
    },
  },
  thisMonth: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  lastWeek: {
    getValue: () => {
      const today = new Date();
      const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() - 7));
      const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
      return { from: firstDayOfWeek, to: lastDayOfWeek };
    },
  },
  lastMonth: {
    getValue: () => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q1: {
    getValue: (year?) => {
      const today = new Date();
      const firstDayOfMonth = new Date(year ?? today.getFullYear(), 0, 1);
      const lastDayOfMonth = new Date(year ?? today.getFullYear(), 2, 31);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q2: {
    getValue: (year?) => {
      const today = new Date();
      const firstDayOfMonth = new Date(year ?? today.getFullYear(), 3, 1);
      const lastDayOfMonth = new Date(year ?? today.getFullYear(), 5, 30);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q3: {
    getValue: (year?) => {
      const today = new Date();
      const firstDayOfMonth = new Date(year ?? today.getFullYear(), 6, 1);
      const lastDayOfMonth = new Date(year ?? today.getFullYear(), 8, 30);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
  q4: {
    getValue: (year?) => {
      const today = new Date();
      const firstDayOfMonth = new Date(year ?? today.getFullYear(), 9, 1);
      const lastDayOfMonth = new Date(year ?? today.getFullYear(), 11, 31);
      return { from: firstDayOfMonth, to: lastDayOfMonth };
    },
  },
} as const satisfies { [key: string]: { getValue: (year?: number) => { from: Date; to: Date } } };

const SelectDateConditionsDefault: SelectDateConditionType[] = [
  { type: 'from', label: 'Od', selectedLabel: 'od' },
  { type: 'to', label: 'Do', selectedLabel: 'do' },
  { type: 'range', label: 'Rozmedzie od a do', selectedLabel: 'od a do' },
  { type: 'predefinedRange', predefinedVariant: 'thisWeek', label: 'Tento týždeň', selectedLabel: 'tento týždeň' },
  { type: 'predefinedRange', predefinedVariant: 'thisMonth', label: 'Tento mesiac', selectedLabel: 'tento mesiac' },
  { type: 'predefinedRange', predefinedVariant: 'lastWeek', label: 'Minulý týždeň', selectedLabel: 'minulý týždeň' },
  { type: 'predefinedRange', predefinedVariant: 'lastMonth', label: 'Minulý mesiac', selectedLabel: 'minulý mesiac' },
  { type: 'predefinedRange', predefinedVariant: 'q1', label: '1.kvartál', selectedLabel: '1.kvartál' },
  { type: 'predefinedRange', predefinedVariant: 'q2', label: '2.kvartál', selectedLabel: '2.kvartál' },
  { type: 'predefinedRange', predefinedVariant: 'q3', label: '3.kvartál', selectedLabel: '3.kvartál' },
  { type: 'predefinedRange', predefinedVariant: 'q4', label: '4.kvartál', selectedLabel: '4.kvartál' },
];

const SelectYearComponent = ({
  onYearChange,
  value,
}: {
  onYearChange: (e: FilterEvent, year: number) => void;
  value: number;
}) => {
  return (
    <YearPicker
      value={value}
      maxYear={new Date().getFullYear()}
      minYear={2010}
      className={'ps-w-full ps-w-[80px] ps-rounded-none ps-h-[36px] ps-bg-gray-100 ps-text-gray-800 ps-weight-bold'}
      onYearChange={onYearChange}
    />
  );
};

export const DateFilterComponent: React.FC<DateFilterComponentProps> = ({
  label,
  conditions = SelectDateConditionsDefault,
  value,
  type,
  id,
  onChange,
  attributeName,
  onDeleteFilter,
}) => {
  const filterType = value?.condition ?? null;

  const valueFrom = value?.value.from ?? null;
  const valueTo = value?.value.to ?? null;

  const onModeChangeValue = (e: FilterEvent, condition: SelectDateConditionType, year?: number) => {
    const mode = condition.type;

    if (mode === 'predefinedRange') {
      const { from, to } = PredefinedRangesConfig[condition.predefinedVariant].getValue(year);
      onChange(e, {
        id,
        type,
        attributeName,
        condition: mode,
        predefinedRange: condition.predefinedVariant,
        value: { from: format(from, 'yyyy-MM-dd'), to: format(to, 'yyyy-MM-dd') },
      });
    } else if (mode === 'from' || mode === 'to' || mode === 'range') {
      onChange(e, {
        id,
        type,
        attributeName,
        condition: mode,
        value: { from: null, to: null },
      });
    }
  };

  const [conditionSelectOpened, setConditionSelectOpened] = React.useState<boolean>(false);
  const [dateFromOpened, setDateFromOpened] = React.useState<boolean>(false);
  const [dateToOpened, setDateToOpened] = React.useState<boolean>(false);
  const handleConditionSelectOpened = () => setConditionSelectOpened((value) => !value);
  const handleDateFromOpened = () => setDateFromOpened((value) => !value);
  const handleDateToOpened = () => setDateToOpened((value) => !value);

  return (
    <div className="ps-flex ps-flex-row">
      <OutlinedFilledButton corners={'rounded-l'}>{label}</OutlinedFilledButton>
      <SelectModeComponent
        conditions={conditions}
        onChange={(e, condition) => {
          setDateFromOpened(false);
          setDateToOpened(false);
          onModeChangeValue(e, condition);
          if (condition.type === 'from' || condition.type === 'range') {
            handleDateFromOpened();
          } else if (condition.type === 'to') {
            handleDateToOpened();
          }
          handleConditionSelectOpened();
        }}
        filterType={filterType}
        predefinedVariant={value?.predefinedRange}
        dropdownMenuOpened={conditionSelectOpened}
        handleDropdownMenuOpened={handleConditionSelectOpened}
      />

      {filterType === 'from' && (
        <CalendarDropdown
          value={valueFrom || null}
          dropdownOpened={dateFromOpened}
          handleDropdownOpened={handleDateFromOpened}
          onChangeValue={(e, value) => {
            onChange(e, {
              id,
              type,
              attributeName,
              condition: filterType,
              value: { from: value, to: null },
            });
            handleDateFromOpened();
          }}
        />
      )}

      {filterType === 'to' && (
        <CalendarDropdown
          value={value?.value.to || null}
          dropdownOpened={dateToOpened}
          handleDropdownOpened={handleDateToOpened}
          onChangeValue={(e, value) => {
            onChange(e, {
              id,
              type,
              attributeName,
              condition: filterType,
              value: { from: null, to: value },
            });
            handleDateToOpened();
          }}
        />
      )}

      {filterType === 'range' && (
        <>
          <CalendarDropdown
            value={value?.value.from || null}
            dropdownOpened={dateFromOpened}
            handleDropdownOpened={handleDateFromOpened}
            onChangeValue={(e, value) => {
              onChange(e, {
                id,
                type,
                attributeName,
                condition: filterType,
                value: { from: value, to: valueTo },
              });
              handleDateFromOpened();
              handleDateToOpened();
            }}
          />
          <CalendarDropdown
            value={value?.value.to || null}
            dropdownOpened={dateToOpened}
            handleDropdownOpened={handleDateToOpened}
            onChangeValue={(e, value) => {
              onChange(e, {
                id,
                type,
                attributeName,
                condition: filterType,
                value: { from: valueFrom, to: value },
              });
              handleDateToOpened();
            }}
          />
        </>
      )}

      {filterType === 'predefinedRange' && ['q1', 'q2', 'q3', 'q4'].includes(value!.predefinedRange!) && (
        <SelectYearComponent
          onYearChange={(e, year) => {
            console.log('valueFrom', year);
            onModeChangeValue(
              e,
              {
                type: 'predefinedRange',
                predefinedVariant: value!.predefinedRange!,
                label: '',
                selectedLabel: '',
              },
              year
            );
          }}
          value={getYear(valueFrom!)}
        />
      )}

      {/* Buttons to reset or apply filters */}
      <OutlinedFilledButton corners={'rounded-r'}>
        <IconButton onClick={onDeleteFilter} data-testId={`${id}-delete-filter`}>
          <Trash2 />
        </IconButton>
      </OutlinedFilledButton>
    </div>
  );
};
