import React from 'react';
import { format } from 'date-fns';
import { DropdownMenu, DropdownMenuContent, FilterEvent, OutlinedFilledButton } from '@components';
import { Calendar } from '@components/calendar';

export const CalendarDropdown = ({
  onChangeValue,
  value,
  dropdownOpened,
  handleDropdownOpened,
}: {
  onChangeValue: (e: FilterEvent, value: string) => void;
  value: string | null;
  dropdownOpened?: boolean;
  handleDropdownOpened?: () => void;
}) => {
  return (
    <DropdownMenu
      id={`date-conditions`}
      open={dropdownOpened}
      onOpenChange={handleDropdownOpened}
      content={
        <DropdownMenuContent className={'ps-p-2'}>
          <Calendar
            mode="single"
            autoFocus
            selected={value ? new Date(value) : undefined}
            onSelect={(date, _trigerDate, _modifiers, e) => {
              if (date) onChangeValue(e as FilterEvent, format(date, 'yyyy-MM-dd'));
            }}
          />
        </DropdownMenuContent>
      }
    >
      <OutlinedFilledButton corners={'square'}>
        {value ? format(new Date(value), 'dd.MM.yyyy') : 'vyberte dátum'}
      </OutlinedFilledButton>
    </DropdownMenu>
  );
};
