import { SyntheticEvent } from 'react';
import { DefaultLabelsFilters } from '@components';
import { DateFilterInterface, DateFilterValue } from '@components/filter/filters/date-filter/types';
import {
  MultipleSelectFilterInterface,
  MultipleSelectFilterValue,
} from '@components/filter/filters/multiple-select-filter/types';
import { SelectFilter, SelectFilterValue } from '@components/filter/filters/select-filter/types';
import { NumberFilter, NumberFilterValue } from '@components/filter/filters/number-filter/types';
import { TextFilter, TextFilterValue } from '@components/filter/filters/text-filter/types';

export const FilterTypeEnum = {
  SELECT: 'select',
  NUMBER: 'number',
  TEXT: 'text',
  MULTIPLE_SELECT: 'multiple-select',
  DATE: 'date',
} as const;

export type FilterTypes = (typeof FilterTypeEnum)[keyof typeof FilterTypeEnum];

export type FilterEvent = SyntheticEvent | Event | React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLSelectElement>;

export interface FiltersComponentProps {
  filters: FilterType[];
  value: FilterValue[];
  onChange: (e: FilterEvent, value: FilterValue[]) => void;
  deleteAllButton?: boolean;
  labels?: typeof DefaultLabelsFilters;
}

export type FilterType = SelectFilter | MultipleSelectFilterInterface | DateFilterInterface | NumberFilter | TextFilter;
export type FilterValue = SelectFilterValue | MultipleSelectFilterValue | DateFilterValue | NumberFilterValue | TextFilterValue;

export type Condition<T> = {
  type: T;
  selectedLabel?: string;
  label: string;
};

export interface FilterBase<T> {
  id: string;
  type: T;
}
