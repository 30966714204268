import React from 'react';
import { ChevronDown, X as ClearIcon } from 'react-feather';
import FieldWrapper from '@components/field/field-wrapper';
import { FieldSkeleton } from '@components/field/skeleton';
import { FieldPropsBase } from '@components/field/types';
import { IconButton } from '@components/icon-button';
import { InputProps, inputInputVariants, inputVariants } from '@components/input';
import { useForwardedRef } from '@hooks/use-forwarded-ref';
import { commonCn } from '@utils/cn';

export type SelectProps<T> = Omit<React.ComponentPropsWithoutRef<'select'>, 'onChange' | 'value' | 'size'> &
  FieldPropsBase &
  Omit<InputProps, 'value' | 'defaultValue' | 'onChange' | 'error' | 'size'> & {
    options: T[];
    value?: string | null;
    defaultValue?: string;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement> | null, value: string | null) => void;
    getOptionLabel?: (option: T) => any;
    getOptionValue?: (option: T) => string;
    selectRef?: React.Ref<HTMLSelectElement>;
    showClearIcon?: boolean;
    size?: 'sm' | 'md' | 'lg';
  };

export const SelectInner = <T,>(
  {
    name,
    onChange,
    options,
    getOptionLabel = (option) => (option as { code?: string; name?: string })?.name ?? '',
    getOptionValue = (option) => (option as { code?: string; name?: string })?.code ?? '',
    disabled,
    value,
    highlight,
    size,
    error,
    className,
    isLoading,
    label,
    helperText,
    selectRef,
    labelProps,
    infoText,
    required,
    defaultValue,
    placeholder,
    showClearIcon,
    ...rest
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const localSelectRef = useForwardedRef(selectRef ?? null);

  if (isLoading) {
    return <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea={false} />;
  }

  return (
    <FieldWrapper {...{ label, labelProps, required, infoText, helperText, error, inputId: rest.id }} ref={ref}>
      {({ errorAriaAttributes, helperTextAttributes, id }) => (
        <div
          className={commonCn(
            inputVariants({
              className,
              disabled,
              highlight,
              size,
              error: !!error,
            }),
            'ps-grid ps-cursor-pointer',
            className
          )}
        >
          <div className={'ps-col-start-1 ps-row-start-1 -ps-mr-2 '}>
            <select
              ref={selectRef}
              id={id}
              name={name}
              value={value ?? ''}
              defaultValue={defaultValue}
              onChange={(e) => onChange && onChange(e, e.target.value || null)}
              disabled={disabled}
              className={commonCn(
                'ps-cursor-pointer ps-appearance-none ps-outline-none',
                inputInputVariants({ size }),
                !value && 'ps-text-gray'
              )}
              {...errorAriaAttributes}
              {...helperTextAttributes}
              {...rest}
            >
              <option value="" disabled>
                {placeholder}
              </option>

              {options.map((option, index) => {
                const optionValue = getOptionValue(option);
                const optionLabel = getOptionLabel(option);
                return (
                  <option key={`${optionValue}-${index}`} value={optionValue}>
                    {optionLabel}
                  </option>
                );
              })}
            </select>
          </div>

          {showClearIcon && !disabled && (
            <IconButton
              className="ps-relative ps-right-3 ps-z-10 ps-col-start-1 ps-row-start-1 ps-h-[20px] ps-w-[20px] ps-self-center ps-justify-self-end ps-p-0"
              onClick={() => {
                if (onChange) onChange(null, null);
                else if (localSelectRef?.current) localSelectRef.current.value = '';
              }}
              data-testid={'clear-indicator'}
            >
              <ClearIcon size={20} />
            </IconButton>
          )}

          <IconButton className="ps-pointer-events-none ps-relative ps-right-0 ps-z-10 ps-col-start-1 ps-row-start-1 ps-h-[20px] ps-w-[20px] ps-self-center ps-justify-self-end ps-p-0">
            <ChevronDown size={20} />
          </IconButton>
        </div>
      )}
    </FieldWrapper>
  );
};

// forward ref does not work with generic
export type SelectType = <T>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof SelectInner>;

const SelectMemoized = React.memo(React.forwardRef(SelectInner));
SelectMemoized.displayName = 'Select';

export const Select = SelectMemoized as SelectType;
