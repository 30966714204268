import React from 'react';
import {
  ButtonProps,
  DropdownMenu,
  DropdownMenuContent,
  OutlinedFilledButton,
  TextField,
  TruncatedText,
} from '@components';
import { TextFilterComponentProps } from '@components/filter/filters/text-filter/types';

export interface TextFilterInputProps {
  value: NonNullable<TextFilterComponentProps['value']>;
  onChange: TextFilterComponentProps['onChange'];
  id: TextFilterComponentProps['id'];
  corners: ButtonProps['corners'];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const TextFilterInput: React.FC<TextFilterInputProps> = ({
  value,
  onChange,
  id,
  corners,
  open,
  onOpenChange,
}) => {
  return (
    <DropdownMenu
      id={`${id}-filter-options`}
      open={open}
      onOpenChange={onOpenChange}
      content={
        <DropdownMenuContent className={'ps-p-0.5'}>
          <TextField
            value={value.value ?? ''}
            autoFocus
            onChange={(e) => {
              const newValue = !e.target.value || e.target.value === '' ? null : e.target.value;
              onChange(e, { ...value, value: newValue });
            }}
          />
        </DropdownMenuContent>
      }
    >
      <OutlinedFilledButton corners={corners} className={'ps-max-w-24'}>
        <TruncatedText>{value.value ?? 'napíšte text'}</TruncatedText>
      </OutlinedFilledButton>
    </DropdownMenu>
  );
};
