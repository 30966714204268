import React from 'react';
import { ButtonProps, DropdownMenu, DropdownMenuContent, NumberField, OutlinedFilledButton } from '@components';
import { NumberFilterComponentProps } from '@components/filter/filters/number-filter/types';

export interface NumberFilterInputProps {
  value: NonNullable<NumberFilterComponentProps['value']>;
  onChange: NumberFilterComponentProps['onChange'];
  id: NumberFilterComponentProps['id'];
  numberInputFormat: NumberFilterComponentProps['numberInputFormat'];
  corners: ButtonProps['corners'];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const NumberFilterInput: React.FC<NumberFilterInputProps> = ({
  value,
  onChange,
  id,
  numberInputFormat,
  corners,
  open,
  onOpenChange,
}) => {
  const labelByCondition = {
    min: (value: number | null) => `od ${value ?? ''}`,
    max: (value: number | null) => `do ${value ?? ''}`,
    equals: (value: number | null) => value ?? 'napíšte číslo',
  };

  if (value.condition === 'between') {
    return (
      <>
        <DropdownMenu
          id={`${id}-filter-options`}
          open={open}
          onOpenChange={onOpenChange}
          content={
            <DropdownMenuContent className={'ps-p-0.5'}>
              <NumberField
                value={value.value?.at(0)}
                autoFocus
                onValueChange={(values, source) => {
                  const newValues: typeof value.value = [values.floatValue ?? null, value.value?.at(1) ?? null];
                  onChange(source.event!, { ...value, value: newValues });
                }}
                {...numberInputFormat}
              />
            </DropdownMenuContent>
          }
        >
          <OutlinedFilledButton corners={'square'}>od {value.value?.at(0)}</OutlinedFilledButton>
        </DropdownMenu>
        <DropdownMenu
          id={`${id}-filter-options`}
          content={
            <DropdownMenuContent className={'ps-p-0.5'}>
              <NumberField
                value={value.value?.at(1)}
                autoFocus
                onValueChange={(values, source) => {
                  const newValues: typeof value.value = [value.value?.at(0) ?? null, values.floatValue ?? null];
                  onChange(source.event!, { ...value, value: newValues });
                }}
                {...numberInputFormat}
              />
            </DropdownMenuContent>
          }
        >
          <OutlinedFilledButton corners={corners}>do {value.value?.at(1)}</OutlinedFilledButton>
        </DropdownMenu>
      </>
    );
  }

  return (
    <DropdownMenu
      id={`${id}-filter-options`}
      open={open}
      onOpenChange={onOpenChange}
      content={
        <DropdownMenuContent className={'ps-p-0.5'}>
          <NumberField
            value={value.value}
            autoFocus
            onValueChange={(values, source) => onChange(source.event!, { ...value, value: values.floatValue ?? null })}
            {...numberInputFormat}
          />
        </DropdownMenuContent>
      }
    >
      <OutlinedFilledButton corners={corners}>{labelByCondition[value.condition](value.value)}</OutlinedFilledButton>
    </DropdownMenu>
  );
};
