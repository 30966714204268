import React from 'react';
import isNil from 'lodash/isNil';
import { NumberFormatBaseProps, NumericFormat, NumericFormatProps } from 'react-number-format';
import FieldWrapper from '@components/field/field-wrapper';
import FieldSkeleton from '@components/field/skeleton';
import { FieldPropsBase } from '@components/field/types';
import Input, { InputProps } from '@components/input';
import { numberFormat } from '@utils/number-format';

export type NumericFormatConfigProps = {
  thousandSeparator?: NumericFormatProps['thousandSeparator'];
  decimalSeparator?: NumericFormatProps['decimalSeparator'];
  allowedDecimalSeparators?: NumericFormatProps['allowedDecimalSeparators'];
  thousandsGroupStyle?: NumericFormatProps['thousandsGroupStyle'];
  decimalScale?: NumericFormatProps['decimalScale'];
  fixedDecimalScale?: NumericFormatProps['fixedDecimalScale'];
  allowNegative?: NumericFormatProps['allowNegative'];
  allowLeadingZeros?: NumericFormatProps['allowLeadingZeros'];
};

export type NumberFieldProps = FieldPropsBase &
  Omit<InputProps, 'value' | 'defaultValue' | 'type' | 'error'> &
  NumericFormatConfigProps & {
    value?: string | number | null;
    defaultValue?: string | number | null;
    type?: 'number' | 'euro' | 'percent' | 'year' | 'month';
    inputRef?: React.Ref<HTMLInputElement>;

    onValueChange?: NumberFormatBaseProps['onValueChange'];
  };

const getEndAdornment = (value: number | null, type: NumberFieldProps['type']) => {
  if (!type) return;

  const endAdornments = {
    number: undefined,
    euro: ' €',
    percent: ' %',
    year: (value: number | null) => numberFormat.yearsSuffix(value),
    month: (value: number | null) => numberFormat.monthsSuffix(value),
  };
  const endAdornment = endAdornments[type];

  return typeof endAdornment === 'function' ? endAdornment(value) : endAdornment;
};

const NumberFieldInner = (
  {
    isLoading,
    type,
    rightAdornment,
    label,
    labelProps,
    required,
    error,
    helperText,
    infoText,
    value,
    defaultValue,
    inputRef,
    ...rest
  }: NumberFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const preparedValue = !isNil(value) ? parseFloat(value.toString().replace(/\.00$/, '')) : null;
  const finalEndAdornment = rightAdornment ?? getEndAdornment(preparedValue, type);

  if (isLoading) {
    return <FieldSkeleton hasLabel={!!label} hasError={!!error} hasHelperText={!!helperText} isTextarea={false} />;
  }

  return (
    <FieldWrapper {...{ label, labelProps, required, infoText, helperText, error, inputId: rest.id }} ref={ref}>
      {({ errorAriaAttributes, helperTextAttributes, id }) => (
        <NumericFormat
          customInput={Input}
          value={value ?? ''}
          defaultValue={defaultValue ?? ''}
          {...errorAriaAttributes}
          {...helperTextAttributes}
          textAlign={finalEndAdornment ? 'right' : undefined}
          thousandSeparator={type === 'euro' ? ' ' : false}
          allowedDecimalSeparators={[',', '.']}
          decimalScale={2}
          decimalSeparator={','}
          getInputRef={inputRef}
          rightAdornment={finalEndAdornment}
          {...rest}
          id={id}
        />
      )}
    </FieldWrapper>
  );
};

const MemoizedNumberFieldInner = React.memo(React.forwardRef(NumberFieldInner));
MemoizedNumberFieldInner.displayName = 'NumberField';

export const NumberField = MemoizedNumberFieldInner as typeof MemoizedNumberFieldInner & {
  Skeleton: typeof FieldSkeleton;
};
Object.assign(NumberField, {
  Skeleton: FieldSkeleton,
});
