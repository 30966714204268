import React from 'react';
import { Trash2 } from 'react-feather';
import { Divider, DropdownMenu, DropdownMenuContent, MuiStack, Tooltip, Typography } from '@components';
import { OutlinedFilledButton } from '@components/filter/filter';
import { NumberFilterInput } from '@components/filter/filters/number-filter/number-filter-input';
import { NumberFilterComponentProps } from '@components/filter/filters/number-filter/types';
import { FilterSelectItem } from '@components/filter/filters/select-filter/dropdown-select-item';

export const NumberFilterDefaultLabels = {
  conditionDefault: 'vyberte možnosť',
  conditionsHeader: 'Vyberte možnosť',
  tooltipDelete: 'Vymazať filter',
};

export const NumberFilterComponent: React.FC<NumberFilterComponentProps> = ({
  label,
  id,
  type,
  value,
  onChange,
  conditions,
  onDeleteFilter,
  deletable = true,
  labels = NumberFilterDefaultLabels,
  numberInputFormat,
}) => {
  const [conditionSelectOpened, setConditionSelectOpened] = React.useState<boolean>(false);
  const [inputOpened, setInputOpened] = React.useState<boolean>(false);

  return (
    <MuiStack direction={'row'}>
      <OutlinedFilledButton corners={'rounded-l'}>{label}</OutlinedFilledButton>

      <DropdownMenu
        id={`${id}-conditions`}
        open={conditionSelectOpened}
        onOpenChange={setConditionSelectOpened}
        content={
          <DropdownMenuContent className={'ps-p-2'}>
            <Typography variant={'h4'} className={'ps-mx-1 ps-text-gray-800 ps-font-semibold'}>
              {labels?.conditionsHeader}
            </Typography>
            <Divider className={'ps-mx-1'} />
            {conditions?.map((condition, index) => {
              const checked = value?.condition === condition.type;

              return (
                <FilterSelectItem
                  key={index}
                  index={index}
                  checked={checked}
                  type={'radio'}
                  onChange={(e) => {
                    if (!value) {
                      onChange(e, { id, type, value: null, condition: condition.type });
                    } else {
                      if (condition.type === 'between') {
                        if (Array.isArray(value.value)) {
                          onChange(e, { ...value, condition: condition.type, value: value.value });
                        } else {
                          onChange(e, { id, type, value: null, condition: condition.type });
                        }
                      } else {
                        if (typeof value.value === 'number') {
                          onChange(e, { ...value, condition: condition.type, value: value.value });
                        } else {
                          onChange(e, { id, type, value: null, condition: condition.type });
                        }
                      }
                    }

                    setConditionSelectOpened(false);
                    setInputOpened(true);
                  }}
                >
                  {condition.label}
                </FilterSelectItem>
              );
            })}
          </DropdownMenuContent>
        }
      >
        <OutlinedFilledButton corners={'square'}>
          {value?.condition ? '=' : labels?.conditionDefault}
        </OutlinedFilledButton>
      </DropdownMenu>

      {value && (
        <NumberFilterInput
          value={value}
          onChange={onChange}
          id={id}
          numberInputFormat={numberInputFormat}
          corners={deletable ? 'square' : 'rounded-r'}
          open={inputOpened}
          onOpenChange={setInputOpened}
        />
      )}

      {deletable && (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <OutlinedFilledButton corners={'rounded-r'} onClick={onDeleteFilter} data-testId={`${id}-delete-filter`}>
              <Trash2 size={20} />
            </OutlinedFilledButton>
          </Tooltip.Trigger>
          <Tooltip.Content align={'start'}>{labels?.tooltipDelete}</Tooltip.Content>
        </Tooltip>
      )}
    </MuiStack>
  );
};
